<template>
  <div class="work">
    <!-- tab -->
    <div>
      <van-sticky>
        <van-tabs
          v-model="active"
          @click="tabClick"
          color="#25262b"
          background="#ffc200"
        >
          <van-tab title="待接单" name="2"></van-tab>
          <van-tab title="执行中" name="3"></van-tab>
          <van-tab title="待评价" name="4"></van-tab>
          <van-tab title="已评价" name="5"></van-tab>
          <van-tab title="已退订" name="9"></van-tab>
        </van-tabs>
        <van-search
          v-model="search"
          disabled
          placeholder="请选择搜索关键词"
          background="#ffc200"
          shape="round"
          @click="searchshow = true"
        />
      </van-sticky>
    </div>
    <!-- 列表内容 -->
    <div>
      <div class="workList" v-for="item in list" :key="item.index">
        <div class="listTitle">
          <span>{{ item.OSerKindDesc }}</span>
          <span>{{ item.OTypeDesc }}</span>
          <span>{{ item.StatusDesc }}</span>
        </div>
        <div class="listSteps">
          <div class="listShop">
            <div>
              <van-icon name="shop-collect-o" /><span
                style="font-weight: 700; font-size: 16px; margin-left: 8px"
                >商 家</span
              ><span style="font-size: 14px; margin-left: 30px; color: #888">{{
                item.StaName
              }}</span>
            </div>
            <div style="margin: 5px 30px; font-size: 11px; color: #888">
              地址: {{ item.OrgName }} - {{ item.RName }}
            </div>
          </div>
          <div class="listShop">
            <div>
              <van-icon name="user-o" /><span
                style="font-weight: 700; font-size: 16px; margin-left: 8px"
                >客 户</span
              ><span style="font-size: 14px; margin-left: 30px; color: #888"
                >{{ item.OMName }} &nbsp;&nbsp;&nbsp;{{ item.OMMobile }}</span
              >
            </div>
            <div style="margin: 5px 30px; font-size: 11px; color: #888">
              地址: {{ item.OMAddr }}
            </div>
          </div>
          <div class="listRe">
            <div>
              &nbsp;金&nbsp;&nbsp;额:
              <span style="color: #fa6854">¥ {{ item.Amount }} 元</span>
            </div>
            <div>
              下单时间 :
              <span style="color: #888">{{ item.OrderTime }}</span>
            </div>
          </div>
          <div
            style="width:100%;border:1px solid #ffc200;height85px;border-radius: 8px;margin-top:10px"
            v-if="item.SerImgUrl"
          >
            <img
              :src="item.SerImgUrl"
              alt=""
              style="width: 70px; height: 70px; margin: 7px"
            />
          </div>
          <div class="listRe" v-if="item.Status == 9" style="margin: 8px 0">
            <div>
              &nbsp;退订类型 :
              <span style="color: #f2812e">{{ item.RevTypeDesc }} </span>
            </div>
            <div>
              退订原因 :
              <span style="color: #008efa">{{ item.RevReason }}</span>
            </div>
            <div>
              退订时间 :
              <span style="color: #888">{{ item.RevTime }}</span>
            </div>
          </div>
          <div class="listRe" v-if="item.Status == 3" style="margin: 8px 0">
            <div>
              接单人 :
              <span style="color: #008efa">{{ item.RecMan }}</span>
            </div>
            <div>
              接单时间 :
              <span style="color: #888">{{ item.RecTime }}</span>
            </div>
          </div>
          <div
            class="listRe"
            v-if="item.Status == 4 || item.Status == 5"
            style="margin: 8px 0"
          >
            <div>
              配送人 :
              <span style="color: #008efa">{{ item.DelMan }}</span>
            </div>
            <div>
              配送时间 :
              <span style="color: #888">{{ item.DelTime }}</span>
            </div>
          </div>
          <div class="listRe" v-if="item.Status == 5" style="margin: 8px 0">
            <div>
              &nbsp;满意度 :
              <van-rate
                v-model="item.Satisfaction"
                :size="15"
                color="#ffd21e"
                void-icon="star"
                void-color="#eee"
                readonly
              />
            </div>
            <div>
              评价内容 :
              <span style="color: #008efa">{{ item.EvalContent }}</span>
            </div>
            <div>
              评价时间 :
              <span style="color: #888">{{ item.EvaTime }}</span>
            </div>
          </div>
          <div class="listOn">
            <van-button
              icon="failure"
              size="small"
              color="#47ccff"
              style="letter-spacing: 2px"
              :to="'/CalYl/personal/' + item.OrderId"
              v-if="item.Status != 9"
            >
              详情
            </van-button>
            <a :href="'tel:' + item.OMMobile">
              <van-button
                icon="phone-o"
                size="small"
                type="primary"
                style="margin-left: 10px; letter-spacing: 2px; color: #fff"
                v-if="item.Status != 9"
              >
                联系顾客
              </van-button>
            </a>
            <div class="listConfirm">
              <van-button
                icon="completed"
                size="small"
                color="linear-gradient(to right, #79c0ff, #4c96e6)"
                @click="arrive(item.OrderId)"
                v-if="item.Status == 3"
              >
                送 达
              </van-button>
              <van-button
                icon="completed"
                size="small"
                color="linear-gradient(to right, #79c0ff, #4c96e6)"
                @click="order(item.OrderId)"
                v-if="item.Status == 2"
              >
                接 单
              </van-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 没有更多 -->
    <div class="hidden">没有更多订单了~</div>
    <!-- 弹出层 -->
    <van-popup v-model="searchshow" position="top" :style="{ height: '37%' }">
      <van-cell-group>
        <van-field v-model="filters.startTime" label="订单开始时间" placeholder="请选择订单开始时间" readonly="readonly" @click="startTimeShow = true" />
        <van-popup
            v-model="startTimeShow"
            position="bottom"
            get-container="body"
          >
            <van-datetime-picker
              show-toolbar
              type="datetime"
              title="选择年月日时分"
              @cancel="startTimeShow = false"
              @confirm="onstartTimeShow"
            >
            </van-datetime-picker>
          </van-popup>
        <van-field v-model="filters.endTime" label="订单结束时间" placeholder="请选择订单结束时间" readonly="readonly" @click="endTimeShow = true" />
        <van-popup
            v-model="endTimeShow"
            position="bottom"
            get-container="body"
          >
            <van-datetime-picker
              show-toolbar
              type="datetime"
              title="选择年月日时分"
              @cancel="endTimeShow = false"
              @confirm="onendTimeShow"
            >
            </van-datetime-picker>
          </van-popup>
        <van-field v-model="filters.oTypeName" label="订 单 类 型" placeholder="请选择订单类型" readonly="readonly" @click="oTypeShow = true" />
        <van-popup
            v-model="oTypeShow"
            position="bottom"
            get-container="body"
          >
            <van-picker
              show-toolbar
              :columns="oTypeList"
              value-key="Name"
              @cancel="oTypeShow = false"
              @confirm="onoTypeShow"
            >
            </van-picker>
          </van-popup>
        <van-field v-model="filters.oSerKindName" label="服 务 类 别" placeholder="请选择服务类别" readonly="readonly" @click="oSerKindShow = true" />
        <van-popup
            v-model="oSerKindShow"
            position="bottom"
            get-container="body"
          >
            <van-picker
              show-toolbar
              :columns="oSerKindList"
              value-key="Name"
              @cancel="oSerKindShow = false"
              @confirm="onoSerKindShow"
            >
            </van-picker>
          </van-popup>
      </van-cell-group>
      <div style="margin:15px 15px">
        <van-button type="default" @click="searchshow = false" style="margin-left:20%">返回</van-button>
      <van-button type="info" @click="onsearch" style="margin-left:20%">搜索</van-button>
      </div>
    </van-popup>
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
Vue.use(Toast);
Vue.use(Dialog);
import { getToken, setToken } from "@/utils/auth";
import {
  WeGetStaOrderPage,
  WeDeliveryOrder,
  WeRecviceOrder,
} from "@/api/Lycal.js";
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      active: 1,
      filters: {
        page: 1,
        limit: 999,
        status: 2,
      },
      search: "",
      acctoken:
        "2f3857384b395c387d667c6657667d6352632f346d3630342f3435625c642f3635615c31303435376d3635382f6370652f37353070322f395c3947642f34573351382f384b3951385c665c66df85",
      // 2f3857384b395c387d667c6657667d6352632f346d3630342f3435625c642f3635615c31303435376d3635382f6370652f37353070322f395c3947642f34573351382f384b3951385c665c66df85
      list: {},
      searchshow: false, //搜索弹框
      startTimeShow:false, //开始时间弹框
      endTimeShow:false,//结束时间弹框
      oTypeShow:false,//订单类型
      oSerKindShow:false, //服务类别弹框
      oSerKindList:[
        {value:0,Name:"全部"},
        {value:1,Name:"助餐"},
        {value:2,Name:"助洁"},
        {value:3,Name:"助行"},
        {value:4,Name:"助浴"},
        {value:5,Name:"助医"},
        {value:6,Name:"康复"},
        {value:7,Name:"巡访关爱"},
        {value:99,Name:"其他"},
      ],
      oTypeList:[
        {value:0,Name:"全部"},
        {value:1,Name:"服务项目"},
        {value:2,Name:"服务套餐"},
      ]
    };
  },
  mounted() {
    // this.acctoken = getToken();
    this.getlist();
  },
  methods: {
    // 跳转
    jumpUrl(row){
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + row,
        });
      } else {
        this.$router.push(row);
      }
    },
    // tab点击
    tabClick(e) {
      this.filters.status = e;
      this.getlist();
    },
    // 送达
    arrive(val) {
      Dialog.confirm({
        title: "提示",
        message: "是否已送达服务!",
      })
        .then(() => {
          WeDeliveryOrder({ accToken: this.acctoken, OrderId: val }).then(
            (res) => {
              if (res.data.code == 0) {
                Toast.success("送达成功!");
                this.getlist();
              } else {
                Toast.fail(res.data.msg);
              }
            }
          );
        })
        .catch(() => {
          // on cancel
        });
    },
    // 获取列表
    getlist() {
      this.filters.accToken = this.acctoken;
      WeGetStaOrderPage(this.filters).then((res) => {
        this.list = res.data.data;
      });
    },
    // 接单
    order(val) {
      Dialog.confirm({
        title: "提示",
        message: "是否确认接单!",
      })
        .then(() => {
          WeRecviceOrder({
            accToken: this.acctoken,
            OrderId: val,
          }).then((res) => {
            if (res.data.code == 0) {
              Toast.success("接单成功!");
              this.getlist();
            } else {
              Toast.fail(res.data.msg);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 选择器
    onsearch(){
      this.getlist();
      this.searchshow = false;
    },
    onstartTimeShow(val){
      this.filters.startTime = this.dateformat(val);
      this.startTimeShow = false;
    },
    onendTimeShow(val){
      this.filters.endTime = this.dateformat(val);
      this.startTimeShow = false;
    },
    onoTypeShow(val){
      this.filters.oType = val.value;
      this.filters.oTypeName = val.Name;
      this.oTypeShow = false;
    },
    onoSerKindShow(val){
      this.filters.oSerKind = val.value;
      this.filters.oSerKindName = val.Name;
      this.oSerKindShow = false;
    },
    dateformat(val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      let hours = val.getHours();
      let minutes = val.getMinutes();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      if (hours >= 1 && hours <= 9) {
        hours = `0${hours}`;
      }
      if (minutes >= 1 && minutes <= 9) {
        minutes = `0${minutes}`;
      }
      return `${year}-${month}-${day} ${hours}:${minutes}:00`;
    },
  },
};
</script>
<style scoped>
.work {
  background: #f7f7f9;
}
.workList {
  width: 100%;
  margin: 12px 0;
  background: #fff;
  box-shadow: 0px 0px 3px #ccc;
}
.listTitle {
  position: relative;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #f1f3f4;
}
.listTitle span:nth-child(1) {
  /* margin-left: 15px; */
  font-size: 10px;
  background: #fed001;
  padding: 6px 10px;
  border-radius: 50px;
  font-style: italic;
  letter-spacing: 2px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}
.listTitle span:nth-child(2) {
  padding-left: 5px;
  font-size: 11px;
}
.listTitle span:nth-child(3) {
  position: absolute;
  right: 15px;
  font-weight: 700;
  font-size: 14px;
  color: #fa6205;
}
.listSteps {
  width: 90%;
  margin: 10px 5%;
  padding-bottom: 20px;
}
.hidden {
  margin: 10px 0;
  font-size: 10px;
  color: #ccc;
  text-align: center;
  padding-bottom: 30px;
}
.listRe {
  font-size: 15px;
}
.listRe span {
  font-size: 14px;
  font-weight: 700;
}
.listOn {
  position: relative;
  margin: 10px 0;
}
.listOn .listConfirm {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 35%;
}
.listOn .listConfirm button {
  width: 100%;
  letter-spacing: 2px;
}
.listShop i {
  position: relative;
  top: 5px;
  font-size: 22px;
}
</style>